<template>
    <div>
      <InstallmentsTable
        :useTax="useTax"
        :type="paymentMethod.type"
        :installments="payment.installments"
        :anticipation="paymentMethod.anticipation"
      />
    </div>
  </template>
  <script>
    import { getFirstUtilDay } from '../../utils/financialHelper'
    export default {
      components: { 
        InstallmentsTable: () => import('@/modules/financial/components/PaymentMethodInstallmentsTable')
      },
      props: {
        useTax: Boolean,
        installmentQuantity: Number,
        payment: Object,
        paymentMethod: Object,
      },
      mounted() {
        !this.payment.id && (this.payment.installments = this.inflateInstallments())
      },
      methods: {
        inflateInstallments() {
          const quantity = this.installmentQuantity
          const tax = this.getPaymentMethodTax(quantity)
          const totalAmount = this.payment.amount
          const installmentValue = totalAmount / quantity

          if (this.paymentMethod.type === 'bill_to_receive' && this.paymentMethod.anticipation) {
            return [
              {
                position: 1,
                installment_count: quantity,
                due_date: getFirstUtilDay(this.moment(this.payment.due_date).add(this.paymentMethod.receipt_delay || 0, 'days')),
                payment_date: null,
                change: this.paymentMethod.change,
                amount: totalAmount - (totalAmount * (tax/100)),
                installment_value: this.payment.amount,
                status: 'pending',
                tax,
              }
            ]
          }

          return new Array(quantity)
            .fill({})
            .reduce((acc, el, index) => {
              const position = (index + 1)
              const dueDate = getFirstUtilDay(this.getFutureDate(this.payment.due_date, position))
  
              acc.push({
                position: position,
                installment_count: quantity,
                due_date: dueDate,
                payment_date: null,
                change: this.paymentMethod.change,
                amount: installmentValue - (installmentValue * (tax/100)),
                installment_value: installmentValue,
                status: 'pending',
                tax,
              });
  
              return acc;
            }, []);
        },
        getPaymentMethodTax(position) {
          const installment = this.paymentMethod.installments.find(el => el.position === position - 1);
          return installment && this.useTax ? installment.value : 0;
        },
        getFutureDate(dueDate, months) {
          if (!dueDate) return null;
          return this.moment(dueDate).add(months, 'month').add(this.paymentMethod.receipt_delay || 0, 'days')
        },
        getPaymentDate(paymentMethod, dueDate) {
          return paymentMethod.change === 'auto' ? dueDate : null
        },
      },
      watch: {
        'payment.amount'() {
          this.payment.installments = this.inflateInstallments()
        },
        'payment.due_date'() {
          this.payment.installments = this.inflateInstallments()
        },
        installmentQuantity() {
          this.payment.installments = this.inflateInstallments()
        }
      }
    }
  
  </script>
  <style lang="scss" scoped>
  
  </style>
  